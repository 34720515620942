.lh-35{
	line-height: 35px;
}

.pricing{
	.card-body ul li{
		font-size: 15px;
	}
	h3 {
		padding-left: 20px;
		sup{
			top: 20px;
			left:0px;
		}

		sub{
			font-size: 13px;
		}
	}

	.btn-solid-border:hover{
			color: $light!important;
	}
}


